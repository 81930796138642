<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <v-card class="white shadow1 rounded">
                            <v-toolbar color="white" :elevation="0">
                                <v-toolbar-title class="subtitle-1">Customer Image</v-toolbar-title>
                                <v-combobox dense outlined hide-details v-model="selectedCustomer" @focus="$store.dispatch('customer/getCustomers')" :items="$store.getters['customer/customers']" :loading="$store.getters['customer/loading']" item-text="display_text" item-value="id">
                                </v-combobox>
                            </v-toolbar>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" v-if="selectedCustomer">
                <v-card class="white shadow1 rounded">
                    <v-row no-gutters>
                        <v-col cols="4" style="border: 1px solid #000;padding: 10px; border-radius: 5px;">
                            <div>
                                Front image
                            </div>
                            <img style="display: block;width:100%;" :src="`${hostName}/${selectedCustomer.front_img}`" />
                        </v-col>
                        <v-col cols="4" style="border: 1px solid #000;padding: 10px; border-radius: 5px;">
                            <div>
                                Back image
                            </div>
                            <img style="display: block;width:100%;" :src="`${hostName}/${selectedCustomer.back_img}`" />
                        </v-col>
                        <v-col cols="4" style="border: 1px solid #000;padding: 10px; border-radius: 5px;">
                            <div>
                                Receipt image
                            </div>
                            <img style="display: block;width:100%;" :src="`${hostName}/${selectedCustomer.reciept_img}`" />
                        </v-col>
                        <v-col cols="4" style="border: 1px solid #000;padding: 10px; border-radius: 5px;">
                            <div>
                                Permission image
                            </div>
                            <img style="display: block;width:100%;" :src="`${hostName}/${selectedCustomer.permission_img}`" />
                        </v-col>
                        <v-col cols="4" style="border: 1px solid #000;padding: 10px; border-radius: 5px;">
                            <div>
                                Customer image
                            </div>
                            <img style="display: block;width:100%;" :src="`${hostName}/${selectedCustomer.image5}`" />
                        </v-col>
                        <v-col cols="4" style="border: 1px solid #000;padding: 10px; border-radius: 5px;">
                            <div>
                                Arms image
                            </div>
                            <img style="display: block;width:100%;" :src="`${hostName}/${selectedCustomer.image6}`" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>

import utilities from '../../mixins/utility.mixin'
import lodash from 'lodash'
export default {
    name: 'CustomerImage',

    mixins: [utilities],
    data() {
        return {
            selectedCustomer: null,
            hostName: null,
            customer_list: [],
        }
    },

    watch: {
        selectedCustomer(customer) {
            console.log(customer);
        }
    },

    async created() {
        this.hostName = this.$store.state.hostName
        await this.$store.dispatch('customer/getCustomers');
        this.customer_list = this.$store.getters['customer/customers']

        let userData = JSON.parse(localStorage.getItem('userData'))
        this.userType = userData.userType;
    },

    methods: {
    }
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
    margin-top: 1px !important;
    height: 34px !important;
}

.carv_img_wrap {
    position: relative;
    padding-top: 84px;
}

.carv_img_wrap .carv_img {
    position: absolute;
    bottom: -26px;
    left: 0;
    right: 0;
    width: 144px;
    height: 62px;
    margin: 0 auto;
}

.uavatar {
    width: 64px;
    height: 64px;
    bottom: -32px;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.shadow_f {
    box-shadow: rgba(145, 158, 171, 0.16) 0px 4px 8px 0px !important;
}

// .info_list {
//     padding-top: 50px !important;
// }
.actions_btns {
    padding-top: 45px;
    text-align: center;
}

.doc-subtitle {
    color: #000;
    font-size: 13px;
}

.position-relative {
    position: relative;
}

.doc-image-input {
    position: absolute;
    bottom: -15px;
    left: 40%;
}

.image-input {
    position: absolute;
    bottom: -15px;
    left: 40%;
}

::v-deep .v-icon.v-icon.v-icon--link {
    color: #000 !important;
}
</style>